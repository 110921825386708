import React from "react"
import "./succesBox.scss"
import success from "../../../images/icons/done_sucess.svg";
import { Title } from "../../Layout/Title/title";

export const SuccesBox = props => {
    return (
        <div className="m-SuccesBox">
            <div className="m-SuccesBox__header">
                <div className="m-SuccesBox__icon">
                    <img src={success} />
                </div>
            </div>
            <div className="m-SuccesBox__body">
                <Title regular content="Your payment has been made successfully!" />
            </div>
            <div className="m-SuccesBox__body">
                <p>Your application is now being processed.</p>
                <p>In the next 24-72 hours, you will receive an email with confirmation of your payment and a further email containing a PDF of your completed {props.applicationId} application form.</p>
                <p>If you have yet to receive this email and/or are unable to locate it after this time, please be sure you check your spam folder.</p>
            </div>
        </div>
    )
}
