import React from "react"
import "./sideSummary.scss"
import { Title } from "../../Layout/Title/title"

export const SideSumarySmall = props => {
    return (
        <div className="m-sideSummary m-sideSummarySmall">
            <div className="m-sideSummarySmall__header">
                <Title small content={`Order Summary`} />
            </div>
            <div className="m-sideSummarySmall__wrapper">
                <div className="m-sideSummary__body">
                    <p className="m-sideSummary__txt">
                        Application Reference: { props.applicationId}
                    </p>
                    <p className="m-sideSummary__txt m-sideSummary__txt-strong">
                        { props.applicationId } assistance and filing service: ${ props.applicationPrice }
                    </p>
                </div>
                <div className="m-sideSummarySmall__footer">
                    Total amount: ${ props.applicationPrice }
                </div>
            </div>
        </div>
    )
  }
