import React, { Component } from "react"
import { EncodedCookieDataStore } from "../services/storage/datastore/EncodedCookieDataStore"
import { Base64EncoderService } from "../services/storage/encoder/Base64EncoderService"
import { Base64DecoderService } from "../services/storage/decoder/Base64DecoderService"
import Layout from "../components/Layout/layout"
import Wrapper from "../components/Layout/Wrapper/wrapper"
import { Loading } from "../components/Modules/Loading/loading"
import SEO from "../components/Modules/SEO/seo"
import { SideSumarySmall } from "../components/Modules/SideSummary/SummarySmall"
import { SuccesBox } from "../components/Modules/SuccesBox"
import { GetApplicationNameHandler } from "../services/api/GetApplicationName/GetApplicationNameHandler"
import { GetPricesHandler } from "../services/api/GetPrices/GetPricesHandler"

class ThankYou extends Component {
  constructor(props) {
    super(props)

    const getOrder = new EncodedCookieDataStore({
      cookieName: "address_token",
      decoderService: new Base64DecoderService(),
      encoderService: new Base64EncoderService(),
    })

    this.state = {
      orderReference: getOrder.reference,
      applicationId: getOrder.order,
      form: null,
      loading: true,
      application: {
        applicationName: "",
        applicationNumber: "",
        applicationPrice: {
          id: "",
          name: "",
          amount: "",
        },
      },
    }
  }

  getApplicationPrice = () => {
    const applicationPriceHandler = new GetPricesHandler({})
    applicationPriceHandler.customAction().then(res => {
      let form = this.state.application
      form.applicationPrice = {
        id: res.regularPrice.id,
        name: res.regularPrice.name,
        amount: res.regularPrice.amount,
      }
      this.setState({
        application: form,
        loading: false,
      })
    }).catch((body) => {
      console.log(body)
    })
  }

  getApplicationName = () => {
    const applicationNameHandler = new GetApplicationNameHandler({})
    let data = {}
    data.reference = this.state.applicationId
    applicationNameHandler.customAction(data).then(res => {
      let form = this.state.application
      form.applicationName = res.application.formName
      form.applicationId = this.state.applicationId
      form.applicationNumber = res.application.formDescription
      this.setState({
        application: form,
        loading: false,
      })
    }).catch((body) => {
      //   window.location.href = "/"
    })
  }

  getApplicationData = () => {
    this.getApplicationName()
    this.getApplicationPrice()
  }

  componentDidMount() {

    if ((this.state.applicationId === undefined || this.state.applicationId === "")) {
      window.location.href = "/"
    }

    this.getApplicationData()

    const getOrder = new EncodedCookieDataStore({
      cookieName: "address_token",
      decoderService: new Base64DecoderService(),
      encoderService: new Base64EncoderService(),
    })
    getOrder.reference = undefined;
    getOrder.order = undefined;

  }

  render() {
    const application = this.state.application
    const applicationTitle = application && !this.state.loading ?
      application.applicationNumber + " - " + application.applicationName + " Form" : undefined
    return (
      <Layout headerTitle={applicationTitle} bgColor={`bg-grey`} hideFooterLogo={true}>
        <SEO title="Thank you for trusting us"/>
        <Wrapper small>
          {this.state.loading ?
            <Loading/>
            :
            <>
              <SuccesBox applicationId={application.applicationName}/>
              <SideSumarySmall applicationReference={this.state.orderReference}
                               applicationPrice={application.applicationPrice.amount}
                               applicationId={application.applicationName}/>
            </>
          }
        </Wrapper>
      </Layout>
    )
  }
}

export default ThankYou